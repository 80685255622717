import { useLocaleMetadata } from "@/lib/useLocaleMetadata";
import { useLocale } from "next-intl";
import Script from "next/script";

interface Metadata {
  title: string;
  description: string;
  keywords: string[];
  icons: {
    icon: { url: string; type: string; sizes?: string }[];
  };
  openGraph: {
    title: string;
    description: string;
    url: string;
    siteName: string;
    images: { url: string; width: number; height: number; alt: string }[];
    type: string;
    locale: string;
  };
  twitter: {
    card: "summary_large_image";
    title: string;
    description: string;
    images: string[];
    creator: string;
  };
}

export default function Seo({ page }: { page: string }) {
  const data = useLocaleMetadata(page);
  const locale = useLocale();

  const metadata: Metadata = {
    title: data.title,
    description: data.description,
    keywords: JSON.parse(data.keywords),
    icons: {
      icon: [
        { url: "/favicon.svg", type: "image/svg+xml" },
        { url: "/favicon-32x32.png", sizes: "32x32", type: "image/png" },
        { url: "/favicon.ico", type: "image/x-icon" },
      ],
    },
    openGraph: {
      title: data.title,
      description: data.description,
      url: `https://deskadora.com/${locale}`,
      siteName: "Deskadora",
      images: [
        {
          url: "https://deskadora.com/deskadora-og-image.jpg",
          width: 1200,
          height: 630,
          alt: "Deskadora QR Code Payments and Venue Management Platform",
        },
      ],
      type: "website",
      locale,
    },
    twitter: {
      card: "summary_large_image",
      title: data.title,
      description: data.description,
      images: ["https://deskadora.com/deskadora-twitter-image.jpg"],
      creator: "@Deskadora",
    },
  };

  return (
    <>
      {/* Structured Data (JSON-LD format) */}
      <Script
        id="product-jsonld"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Deskadora",
            url: "https://deskadora.com",
            logo: "https://deskadora.com/logo_small.svg",
            sameAs: [
              "https://www.facebook.com/deskadoraeurope",
              "https://www.instagram.com/deskadoradotcom",
              "https://x.com/deskadoradotcom",
            ],
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "Customer Support",
              areaServed: "GB, FR, BE, LU, CH, IT, RO",
              availableLanguage: ["en", "fr", "ro", "it"],
            },
            description:
              "Deskadora is an innovative venue management platform offering QR code payments, digital ordering, and analytics solutions for cafes, bars, and restaurants.",
          }),
        }}
      />
      {/* General SEO */}
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="keywords" content={metadata.keywords.join(", ")} />
      {metadata.icons.icon.map((icon, index) => (
        <link
          key={index}
          rel="icon"
          href={icon.url}
          sizes={icon.sizes}
          type={icon.type}
        />
      ))}

      {/* Facebook Open Graph */}
      <meta property="og:type" content={metadata.openGraph.type} />
      <meta property="og:title" content={metadata.openGraph.title} />
      <meta
        property="og:description"
        content={metadata.openGraph.description}
      />
      <meta property="og:image" content={metadata.openGraph.images[0].url} />
      <meta property="og:url" content={metadata.openGraph.url} />
      <meta property="og:site_name" content={metadata.openGraph.siteName} />
      <meta property="og:locale" content={metadata.openGraph.locale} />

      {/* Twitter Card */}
      <meta name="twitter:card" content={metadata.twitter.card} />
      <meta name="twitter:title" content={metadata.twitter.title} />
      <meta name="twitter:description" content={metadata.twitter.description} />
      <meta name="twitter:image" content={metadata.twitter.images[0]} />
      <meta name="twitter:site" content={metadata.twitter.creator} />
      <meta name="twitter:creator" content={metadata.twitter.creator} />

      {/* Additional SEO and metadata */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={metadata.openGraph.url} />
    </>
  );
}
