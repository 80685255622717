/* eslint-disable @typescript-eslint/no-unused-vars */
import Image from "next/image";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Link, usePathname, useRouter } from "@/i18n/routing";
import { useTransition } from "react";
import { useParams } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import React from "react";

interface FooterItem {
  url: string;
  label: string;
}

export const Footer = () => {
  const router = useRouter();
  const [, startTransition] = useTransition();
  const pathname = usePathname();
  const params = useParams();

  const t = useTranslations("Footer");

  const onSelectChange = (nextLocale: string) => {
    // Use destructuring to exclude 'locale'
    const { locale, ...otherParams } = params;
    startTransition(() => {
      router.replace({ pathname, query: otherParams }, { locale: nextLocale });
    });
  };

  const locale = useLocale();

  const footerItems: FooterItem[] = [
    {
      label: t.rich("termsLabel") as string,
      url: t.rich("termsUrl") as string,
    },
    {
      label: t.rich("privacyLabel") as string,
      url: t.rich("privacyUrl") as string,
    },
    {
      label: t.rich("cookiesLabel") as string,
      url: t.rich("cookiesUrl") as string,
    },
    {
      label: t.rich("contactLabel") as string,
      url: t.rich("contactUrl") as string,
    },
  ];

  return (
    <footer className="bg-black text-[#BCBCBC] text-sm py-10 text-center">
      <div className="container m-auto">
        <div className="flex flex-col lg:flex-row lg:justify-between items-center">
          <div className="flex flex-col gap-6 justify-center items-center lg:items-start">
            <Image
              src="/logo_small.svg"
              width={40}
              height={40}
              alt="Deskadora logo"
            />
            <Select defaultValue={locale} onValueChange={onSelectChange}>
              <SelectTrigger
                name="langauge"
                className="w-[180px] bg-black/60 border-[#303030] focus:ring-offset-0 focus:ring-1 focus:ring-[#C7EA46]"
              >
                <SelectValue placeholder="Select Language" />
              </SelectTrigger>
              <SelectContent className="bg-black text-white/60 border-[#303030]">
                <SelectItem className="cursor-pointer" value="en">
                  <div className="flex items-center gap-2">
                    <Image
                      src="/flags/en.png"
                      alt="English flag"
                      width={15}
                      height={10}
                    />
                    English
                  </div>
                </SelectItem>
                <SelectItem className="cursor-pointer" value="fr">
                  <div className="flex items-center gap-2">
                    <Image
                      src="/flags/fr.png"
                      alt="French flag"
                      width={15}
                      height={10}
                    />
                    Français
                  </div>
                </SelectItem>
                <SelectItem className="cursor-pointer" value="it">
                  <div className="flex items-center gap-2">
                    <Image
                      src="/flags/it.png"
                      alt="Italian flag"
                      width={15}
                      height={10}
                    />
                    Italiano
                  </div>
                </SelectItem>
                <SelectItem className="cursor-pointer" value="ro">
                  <div className="flex items-center gap-2">
                    <Image
                      src="/flags/ro.png"
                      alt="Romanian flag"
                      width={15}
                      height={10}
                    />
                    Română
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="justify-center items-center lg:justify-items-end">
            <div className="flex justify-center lg:justify-end items-center gap-6 mt-6">
              <Link
                href="https://facebook.com/deskadoraeurope"
                aria-label="Facebook"
              >
                <Image
                  src="/facebook.svg"
                  width={32}
                  height={32}
                  alt="Facebook"
                />
              </Link>
              <Link
                href="https://instagram.com/deskadoradotcom"
                aria-label="Instagram"
              >
                <Image
                  src="/instagram.svg"
                  width={32}
                  height={32}
                  alt="Instagram"
                />
              </Link>
              <Link
                href="https://x.com/deskadoradotcom"
                aria-label="Twitter (X)"
              >
                <Image
                  src="/x_twitter.svg"
                  width={32}
                  height={32}
                  alt="Twitter (X)"
                />
              </Link>
            </div>
            <div className="flex items-center justify-end gap-6 mt-6">
              <Image
                src="/payment_methods.svg"
                width={404}
                height={18}
                alt="Available payment methods: Visa, Mastercard, and more"
              />
            </div>
          </div>
        </div>

        <div className="w-full border border-[#303030] h-[2px] mt-[30px] lg:mt-[130px]"></div>

        <div className="flex flex-col items-center justify-between mt-6 lg:flex-row">
          <nav
            className="flex flex-col gap-6 items-center lg:flex-row"
            aria-label="Footer navigation"
          >
            {footerItems.map((item, index) => (
              <React.Fragment key={index}>
                <Link
                  href={item.url ?? "/"}
                  aria-label="Terms and Conditions"
                  className="nav-link"
                >
                  {item.label}
                </Link>
                {/* Add divider if it's not the last item */}
                {index < footerItems.length - 1 && (
                  <div className="hidden h-3 border border-[#C7EA46] lg:block"></div>
                )}
              </React.Fragment>
            ))}
          </nav>
          <p className="mt-6 lg:mt-0">
            &copy; 2024 Deskadora Solutions. {t.rich("copyright")}
          </p>
        </div>
      </div>
    </footer>
  );
};
