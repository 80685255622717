"use client";

import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import Image from "next/image";
import { NavigationMenuLink } from "@radix-ui/react-navigation-menu";
import { IoClose, IoMenu } from "react-icons/io5";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Link, usePathname, useRouter } from "@/i18n/routing";
import { useTransition } from "react";
import { useParams } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import React from "react";

export const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const router = useRouter();
  const [, startTransition] = useTransition();
  const pathname = usePathname();
  const params = useParams();
  const t = useTranslations("Menu");

  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute("href")?.slice(1);
    const targetElement = document.getElementById(targetId!);

    if (targetElement) {
      const offsetTop =
        targetElement.getBoundingClientRect().top + window.scrollY - 130; // Adjusts for 130px offset

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const onSelectChange = (nextLocale: string) => {
    // Use destructuring to exclude 'locale'
    const { locale, ...otherParams } = params;
    console.log(locale);
    startTransition(() => {
      router.replace({ pathname, query: otherParams }, { locale: nextLocale });
    });
  };

  const locale = useLocale();

  return (
    <nav className="w-full items-center justify-center">
      <div className="flex items-center justify-center w-full py-5 select-none">
        <NavigationMenu className="w-full max-w-[1920px] justify-between px-5 py-2 h-[60px] items-center">
          <NavigationMenuList>
            <NavigationMenuItem>
              <Link href="/" className="flex items-center space-x-2">
                <Image
                  src="/logo_full.svg"
                  width={200}
                  height={80}
                  alt="Deskadora Logo"
                />
              </Link>
            </NavigationMenuItem>
          </NavigationMenuList>

          <div className="hidden md:flex gap-6 items-center">
            <NavigationMenuLink href="#introduction" onClick={handleScroll}>
              {t.rich("items.introduction")}
            </NavigationMenuLink>
            <NavigationMenuLink href="#client-features" onClick={handleScroll}>
              {t.rich("items.clientFeatures")}
            </NavigationMenuLink>
            <NavigationMenuLink href="#venue-features" onClick={handleScroll}>
              {t.rich("items.venueFeatures")}
            </NavigationMenuLink>
            {/* <NavigationMenuLink href="#plans" onClick={handleScroll}>
              {t.rich("items.plans")}
            </NavigationMenuLink> */}
            <NavigationMenuLink href="#why-us" onClick={handleScroll}>
              {t.rich("items.whyUs")}
            </NavigationMenuLink>
          </div>

          <div className="md:hidden">
            <button
              onClick={handleToggleMenu}
              aria-label="Toggle menu"
              className="focus:outline-none"
            >
              <IoMenu className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </NavigationMenu>
      </div>

      {menuOpen && (
        <NavigationMenu className="md:hidden fixed top-0 left-0 w-screen h-screen flex flex-col items-center justify-between border py-[100px] bg-white z-50">
          <div className="absolute flex justify-between w-full top-6 px-5">
            <Image
              src="/logo_small.svg"
              width={40}
              height={40}
              alt="Deskadora Logo"
            />

            {/* Close Button */}
            <button
              onClick={handleToggleMenu}
              aria-label="Close menu"
              className="text-2xl focus:outline-none"
            >
              <IoClose aria-hidden="true" />
            </button>
          </div>

          <div className="flex flex-col gap-4 items-center justify-start py-4">
            <NavigationMenuLink
              className="w-screen text-center mt-20"
              href="#introduction"
              onClick={handleToggleMenu}
            >
              {t.rich("items.introduction")}
            </NavigationMenuLink>
            <NavigationMenuLink
              className="w-screen text-center"
              href="#client-features"
              onClick={handleToggleMenu}
            >
              {t.rich("items.clientFeatures")}
            </NavigationMenuLink>
            <NavigationMenuLink
              className="w-screen text-center"
              href="#venue-features"
              onClick={handleToggleMenu}
            >
              {t.rich("items.venueFeatures")}
            </NavigationMenuLink>
            {/* <NavigationMenuLink
              className="w-screen text-center"
              href="#plans"
              onClick={handleToggleMenu}
            >
              {t.rich("items.plans")}
            </NavigationMenuLink> */}
            <NavigationMenuLink
              className="w-screen text-center"
              href="#why-us"
              onClick={handleToggleMenu}
            >
              {t.rich("items.whyUs")}
            </NavigationMenuLink>
            <NavigationMenuLink asChild>
              <Link href="/contact" onClick={handleToggleMenu} passHref>
                Contact
              </Link>
            </NavigationMenuLink>
          </div>

          <Select defaultValue={locale} onValueChange={onSelectChange}>
            <SelectTrigger
              name="langauge"
              className="w-[180px] bg-white/60 border-[#303030] focus:ring-offset-0 focus:ring-1 focus:ring-[#C7EA46]"
            >
              <SelectValue placeholder="Select Language" />
            </SelectTrigger>
            <SelectContent className="bg-white text-black/60 border-[#303030]">
              <SelectItem className="cursor-pointer" value="en">
                <div className="flex items-center gap-2">
                  <Image
                    src="/flags/en.png"
                    alt="English flag"
                    width={15}
                    height={10}
                  />
                  English
                </div>
              </SelectItem>
              <SelectItem className="cursor-pointer" value="fr">
                <div className="flex items-center gap-2">
                  <Image
                    src="/flags/fr.png"
                    alt="French flag"
                    width={15}
                    height={10}
                  />
                  Français
                </div>
              </SelectItem>
              <SelectItem className="cursor-pointer" value="it">
                <div className="flex items-center gap-2">
                  <Image
                    src="/flags/it.png"
                    alt="Italian flag"
                    width={15}
                    height={10}
                  />
                  Italiano
                </div>
              </SelectItem>
              <SelectItem className="cursor-pointer" value="ro">
                <div className="flex items-center gap-2">
                  <Image
                    src="/flags/ro.png"
                    alt="Romanian flag"
                    width={15}
                    height={10}
                  />
                  Română
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
        </NavigationMenu>
      )}
    </nav>
  );
};
